<template>
  <div
    class="bg-white rounded-xl shadow lg:p-8 pt-4 pl-6 max-w-1500 mx-auto"
  >
    <TagsRow
      v-for="(item, idx) in list"
      :key="`tags-item-${item.label}-${idx}`"
      class="w-full"
      :label="item.label"
      :selectable="item.selectable"
      :multi="item.multi"
      :all="item.all"
      :rightSlotSetting="item.rightSlot"
      :list="item.list"
      :value="selected[item.uniKey]"
      @selected="(v)=>methodSelect(item.uniKey, v)"
    />
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';
import TagsRow from '@/components/filter/TagsRow.vue';

export default {
  name: 'FilterTagsGroup',
  components: {
    TagsRow,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal) {
          this.methodInit();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  methods: {
    methodInit() {
      const result = {};
      this.list.forEach((item) => {
        result[item.uniKey] = item?.multi ? [item?.list[0]] ?? [] : item?.list[0];
      });
      this.selected = result;
    },
    methodSelect(key, value) {
      this.selected[key] = value;
      this.$emit('update', this.selected);
    },
  },
};
</script>
