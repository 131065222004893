<template>
  <div
    v-if="list!==undefined"
    class="flex flex-nowrap overflow-auto mr-2 pb-2 scrollbar-hidden"
  >
    <p
      v-for="(tag, idx) in list"
      :key="`tag-${tag}-${idx}`"
      class="text-xs whitespace-nowrap px-2 py-0.5 border ml-2 rounded-full bg-white"
      :class="[
        textColor,
        borderColor,
      ]"
    >
      {{tag}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'HashtagList',
  props: {
    list: {
      type: Array,
    },
    layout: {
      type: String,
    },
  },
  computed: {
    textColor() {
      if (this.layout) return `text-${this.layout}`;
      return 'text-ash-600';
    },
    borderColor() {
      if (this.layout) return `border-${this.layout}`;
      return 'border-ash-100';
    },
  },
};
</script>
