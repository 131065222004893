<template>
  <div
    v-if="max>0"
    class="flex flex-row py-24 justify-center"
  >
    <div
      :class="[btnClass, bgColorClass]"
      @click="methodTogglePage(current-1 < 1 ? 1 : current-1)"
      class="gtm-pagination-left"
    >
      <DgIcon
        size="10"
        name="caret-left"
        color="ash-600"
      />
    </div>
    <!-- first page -->
    <div
      class="gtm-pagination-itemtext-lg"
      :class="[
        btnClass,
        current === 1 ? activeClass : `text-ash-600 ${bgColorClass}`
      ]"
      @click="methodTogglePage(1)"
    >1</div>
    <!-- frist ellipsis -->
    <div
      v-show="showFirstEllipsis"
      class="gtm-pagination-more text-lg text-ash-600"
      :class="[btnClass, bgColorClass]"
      @click="methodTogglePage(current-(count-1))"
    >...</div>
    <div
      v-for="(item, idx) in showPages"
      :key="`pagination-item-${item}-${idx}`"
      class="gtm-pagination-item text-lg"
      :class="[
        btnClass,
        current === item ? activeClass : `text-ash-600 ${bgColorClass}`
      ]"
      @click="methodTogglePage(item)"
    >{{item}}</div>

    <!-- last ellipsis -->
    <div
      v-show="showLastEllipsis"
      class="gtm-pagination-more text-lg text-ash-600"
      :class="[btnClass, bgColorClass]"
      @click="methodTogglePage(current+(count-1))"
    >...</div>
    <!-- last page -->
    <div
      class="gtm-pagination-item text-lg"
      :class="[
        btnClass,
        current === max ? activeClass : `text-ash-600 ${bgColorClass}`
      ]"
      @click="methodTogglePage(max)"
      v-show="showMax"
    >{{max}}</div>
    <div
      :class="[btnClass, bgColorClass]"
      @click="methodTogglePage(current+1 > max ? max : current+1)"
      class="gtm-pagination-right"
    >
      <DgIcon
        size="10"
        name="caret-right"
        color="ash-600"
      />
    </div>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Pagination',
  props: {
    current: {
      type: Number,
    },
    max: {
      type: Number,
    },
    isDark: {
      type: Boolean,
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {
      btnClass: 'w-10 h-10 rounded-full flex justify-center items-center ml-5 cursor-pointer',
      activeClass: 'bg-primary text-white',
      // current: 1,
      count: 5,
    };
  },
  computed: {
    bgColorClass() {
      return this.isDark ? 'bg-white' : 'bg-ash-50';
    },
    showFirstEllipsis() {
      return (this.current - 1) >= (this.count - 1);
    },
    showLastEllipsis() {
      return (this.max - this.current) >= (this.count - 1);
    },
    showMax() {
      return this.max > 1;
    },
    showPages() {
      if (this.max === 1) {
        return [];
      }
      if (this.showFirstEllipsis && this.showLastEllipsis) {
        return [this.current - 1, this.current, this.current + 1];
      }
      if (this.max > this.count && !this.showFirstEllipsis) {
        return [2, 3, 4, 5];
      }
      if (this.max > this.count && !this.showLastEllipsis) {
        return [this.max - 4, this.max - 3, this.max - 2, this.max - 1];
      }
      return Array.from(Array(this.max - 2)).map((n, i) => i + 2);
    },
  },
  methods: {
    methodTogglePage(page) {
      if (this.current !== page) this.$emit('update', page);
    },
  },
};
</script>
