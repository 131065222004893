<template>
  <button
    class="px-5 py-2 bg-primary-gradient-to-r rounded-full relative flex items-center
    gtm-btn-download"
    @click="$emit(action)"
  >
    <p
      class="text-base text-white"
    >{{label}}</p>
    <DgIcon
      size="12"
      v-if="icon !== 'false'"
      :name="icon"
      color="white"
      class="ml-1.5"
    />
    <a
      ref="download"
      class="opacity-0 invisible"
    ></a>
  </button>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';
// import { mapMutations } from 'vuex';

export default {
  name: 'DownloadButton',
  props: {
    preview: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '下載文件',
    },
    icon: {
      type: String,
      default: 'download',
    },
    action: {
      type: String,
    },
    fileUrl: {
      type: String,
    },
    fileName: {
      type: String,
    },
  },
  components: {
    DgIcon,
  },
  data() {
    return {

    };
  },
  methods: {
    // ...mapMutations({
    //   showNotifyError: 'setNotifyError',
    // }),
    // methodDownload() {
    //   if (this.preview) return;
    //   if (this.fileUrl) {
    //     this.$refs.download.click();
    //   }
    //   if (this.action) {
    //     console.log(this.action);
    //   } else {
    //     this.showNotifyError('無法取得下載資訊, 請先登入');
    //     // this.$router.push({ name: 'Login' });
    //   }
    // },
  },
};
</script>
