var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.max>0)?_c('div',{staticClass:"flex flex-row py-24 justify-center"},[_c('div',{staticClass:"gtm-pagination-left",class:[_vm.btnClass, _vm.bgColorClass],on:{"click":function($event){return _vm.methodTogglePage(_vm.current-1 < 1 ? 1 : _vm.current-1)}}},[_c('DgIcon',{attrs:{"size":"10","name":"caret-left","color":"ash-600"}})],1),_c('div',{staticClass:"gtm-pagination-itemtext-lg",class:[
      _vm.btnClass,
      _vm.current === 1 ? _vm.activeClass : ("text-ash-600 " + _vm.bgColorClass)
    ],on:{"click":function($event){return _vm.methodTogglePage(1)}}},[_vm._v("1")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFirstEllipsis),expression:"showFirstEllipsis"}],staticClass:"gtm-pagination-more text-lg text-ash-600",class:[_vm.btnClass, _vm.bgColorClass],on:{"click":function($event){_vm.methodTogglePage(_vm.current-(_vm.count-1))}}},[_vm._v("...")]),_vm._l((_vm.showPages),function(item,idx){return _c('div',{key:("pagination-item-" + item + "-" + idx),staticClass:"gtm-pagination-item text-lg",class:[
      _vm.btnClass,
      _vm.current === item ? _vm.activeClass : ("text-ash-600 " + _vm.bgColorClass)
    ],on:{"click":function($event){return _vm.methodTogglePage(item)}}},[_vm._v(_vm._s(item))])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLastEllipsis),expression:"showLastEllipsis"}],staticClass:"gtm-pagination-more text-lg text-ash-600",class:[_vm.btnClass, _vm.bgColorClass],on:{"click":function($event){_vm.methodTogglePage(_vm.current+(_vm.count-1))}}},[_vm._v("...")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMax),expression:"showMax"}],staticClass:"gtm-pagination-item text-lg",class:[
      _vm.btnClass,
      _vm.current === _vm.max ? _vm.activeClass : ("text-ash-600 " + _vm.bgColorClass)
    ],on:{"click":function($event){return _vm.methodTogglePage(_vm.max)}}},[_vm._v(_vm._s(_vm.max))]),_c('div',{staticClass:"gtm-pagination-right",class:[_vm.btnClass, _vm.bgColorClass],on:{"click":function($event){return _vm.methodTogglePage(_vm.current+1 > _vm.max ? _vm.max : _vm.current+1)}}},[_c('DgIcon',{attrs:{"size":"10","name":"caret-right","color":"ash-600"}})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }