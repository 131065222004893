<template>
  <div class="head relative min-h-140">
    <DgImg
      :src="bgImg"
      :contain="false"
      :ratio="isMobile ? 375/140 : 0"
      placeholderSrc="head/bgHeaderPlaceholder"
    />
    <div class="max-w-1500 mx-auto absolute inset-0 grid grid-cols-3 px-5 lg:pb-16 pb-8">
      <!-- left -->
      <div class="grid grid-flow-row pb-3 lg:pb-0">
        <div
          class="flex items-center self-end pb-2"
        >
          <!-- title -->
          <div
            v-show="title"
            class="lg:text-4xl text-2xl text-white leading-snug"
          >{{title}}</div>
          <DgIcon
            :size="isMobile ? 10 : 16"
            name="digi"
            color="white"
            class="ml-2"
          />
        </div>
        <!-- subtitle -->
        <div
          v-show="subtitle"
          class="grid grid-flow-col auto-cols-min auto-rows-min items-center gap-x-2"
        >
          <div
            class="lg:text-xl text-xs text-white whitespace-pre"
          >
          {{subtitle}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DgImg from '@/components/base/DgImg.vue';
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Head',
  inject: ['device'],
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    bgImg: {
      type: String,
      default: 'head/bgHeader',
    },
    defaultIdx: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DgImg,
    DgIcon,
  },
  data() {
    return {
      activeIdx: this.defaultIdx,
    };
  },
  computed: {
    isMobile() {
      return this.device?.isMobile ?? false;
    },
  },
  methods: {

  },
};
</script>
