<template>
  <div class="knowledge">
    <Head title="資料下載" :subtitle="subTitle" :bgImg="headBgImg" />
    <div class="px-5">
      <FilterTagsGroup :list="tagList" class="translate-to-head" @update="methodUpdateTag" />
      <div
        class="max-w-1500 mx-auto grid gap-4
          grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <div v-for="(item, idx) in list" :key="`knowledge-card-item-${item.title}-${idx}`">
          <router-link :to="methodGoContent(item.uuid)" class="gtm-knowledge-card">
            <Card
              :imgUrl="item.image"
              :bookmark="item.bookmark"
              :length="item.length"
              :tag="item.tag"
              :type="item.type"
              :title="item.title"
              :avatar="item.avatar"
              :name="item.name"
              :created="item.created"
              :likeCount="item.likeCount"
              :watchedCount="item.watchedCount"
              @click.native="methodOpenModal(item.uuid)"
            />
          </router-link>
        </div>
      </div>
      <Pagination :current="currentPage" :max="maxPage" @update="methodUpdateCurrentPage" />
    </div>
  </div>
</template>

<script>
import requestApi from '@/lib/http/index';
import { mapMutations } from 'vuex';
import Head from '@/components/Head.vue';
import FilterTagsGroup from '@/components/filter/FilterTagsGroup.vue';
import Pagination from '@/components/Pagination.vue';
import Card from '@/components/Card.vue';
import Content from '@/views/home/Content.vue';
// import { tagList } from '@/utils/const';

export default {
  name: 'Index',
  components: {
    Head,
    FilterTagsGroup,
    Card,
    Pagination,
  },
  data() {
    return {
      subTitle: '淬鍊行業智慧與服務能量，提供研討會精華講義、\n智能應用與行業解決方案產品型錄…等，全方位資源。',
      tagList: [
        {
          label: '類別',
          uniKey: 'tag',
          selectable: true,
          multi: true,
          all: true,
          list: [
            {
              label: '全部類別',
              value: '',
            },
          ],
        },
      ],
      list: [],
      searchTags: [],
      // list: tmpList,
      currentPage: 1,
      maxPage: 1,
    };
  },
  computed: {
    headBgImg() {
      return this.currentType?.bgImg ?? 'head/banner';
    },
  },
  methods: {
    ...mapMutations({
      mutationSetModal: 'setModal',
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
    }),

    methodUpdateTag(tag /* tag */) {
      this.currentPage = 1;
      this.searchTags = (() => {
        const arr = [];
        tag.tag.forEach((elem) => {
          arr.push(elem.value);
        });
        return arr;
      })();
      // console.log(this.searchTags);
      this.methodGetData();
    },

    methodUpdateCurrentPage(page) {
      this.currentPage = page;
      this.methodGetData(this.currentPage);
      if ((this.$route.query?.page ?? null) !== `${this.currentPage}`) {
        const { query } = this.$route;
        this.$router.replace({ name: 'Home', query: { ...query, page: this.currentPage } });
      }
    },
    // 前往內容頁
    methodGoContent(uuid) {
      if (uuid) {
        return { name: 'Home', query: { uuid, page: this.currentPage } };
      }
      return '';
    },

    async methodOpenModal(uuid) {
      // console.log('uuid', uuid);
      const { result } = await requestApi('articles.view', {
        uuid,
      });
      if ((this.$route.query?.uuid ?? null) !== uuid) {
        this.$router.replace({ name: 'Home', query: { uuid, page: this.currentPage } });
      }

      this.mutationSetModal({
        show: true,
        width: 1200,
        propsData: {
          uuid,
          value: result.data.content,
          // fileUrl: '',
          // fileName: '',
          // action: 'dlFlie',
          currentPage: this.currentPage,
          coverPath: result.data.cover_path,
          title: result.data.title,
          tags: (() => {
            const arr = [];
            result.data.tags.forEach((elem) => {
              arr.push(elem.name);
            });
            return arr;
          })(),
        },
        closeCallback: () => {
          this.$router.replace({ name: 'Home', query: { page: this.currentPage } });
        },
        contentComponent: Content,
      });
    },

    async methodGetTagsData() {
      const { status, result } = await requestApi('sysVars');

      // console.log(result, this.tagList[0].list);

      this.tagList[0].list = [
        {
          label: '全部類別',
          value: '',
        },
      ];

      if (status) {
        this.tagList[0].list = [
          {
            label: '全部類別',
            value: '',
          },
        ];
        result.tags.forEach((elem) => {
          this.tagList[0].list.push({
            label: elem.name,
            value: elem.id,
          });
        });
      }
    },

    async methodGetData(page = this.currentPage, tagId = this.searchTags) {
      this.list = [];

      // console.log(tagId);

      const { result } = await requestApi('articles', {
        page,
        limit: 12,
        tag_id: tagId[0] === '' ? '' : tagId,
      });

      this.maxPage = result.pageCount;

      result.data.forEach((item) => {
        this.list.push({
          uuid: item.uuid,
          image: item.cover_path,
          tag: (() => {
            const arr = [];
            item.tags.forEach((elem) => {
              arr.push(elem.name);
            });
            return arr;
          })(),
          title: item.title,
        });
      });
    },
  },
  mounted() {
    const { page = 1 } = this.$route.query;
    this.methodGetTagsData();
    // eslint-disable-next-line radix
    this.methodUpdateCurrentPage(parseInt(page));

    if (this.$route.query?.uuid ?? null) {
      const { uuid } = this.$route.query;
      this.methodOpenModal(uuid);
    }
  },
};
</script>
