<template>
  <div
    class="lg:px-12 px-5 lg:py-24 pt-20 pb-14 w-full overflow-auto"
  >
    <div class="grid lg:grid-cols-2 grid-cols-1 lg:grid-flow-col grid-flow-row lg:gap-8 gap-2">
      <DgImg
        :src="coverPath"
        :ratio="16/9"
        class="rounded-lg"
      />
      <div
        class="border-b border-ash-600 border-opacity-20 py-5
        flex flex-col justify-center items-start"
      >
        <div class="pb-2">
          <small
            v-for="(item, i) in tags"
            :key="`item_${i}`"
            class="text-primary border border-primary px-2 py-0.5
              inline-block rounded-full mr-3 mb-3">
                {{item}}
          </small>
        </div>
        <h4 class="lg:text-3xl text-2xl mb-7">
          {{title}}
        </h4>
        <DownloadButton
          v-if="fileUrl || action"
          :fileUrl="fileUrl"
          :fileName="fileName"
          :action="action"
          :label="iconLabel"
          :icon="iconName"
          @dlFlie="dlFlie(uuid)"
        />
      </div>
      <!-- <img src="https://cnet2.cbsistatic.com/img/9tf6BfVtKOpwjMwKYAc2usl0WRg=/105x158:744x517/1200x675/2020/01/20/ada6b2c1-465f-409e-8956-df61acd3a067/bouqs-valentines-day-flowers.jpg" alt=""> -->
    </div>

    <div class="my-8 editor-content" v-html="value">
    </div>

    <DownloadButton
      class="mx-auto"
      v-if="fileUrl || action"
      :fileUrl="fileUrl"
      :fileName="fileName"
      :action="action"
      :label="iconLabel"
      :icon="iconName"
      @dlFlie="dlFlie(uuid)"/>
  </div>
</template>

<script>
import requestApi from '@/lib/http/index';
import { mapMutations, mapState } from 'vuex';
import DownloadButton from '@/components/DownloadButton.vue';
import DgImg from '@/components/base/DgImg.vue';

export default {
  name: 'Content',
  props: {
    uuid: {
      type: String,
    },
    value: {
      type: String,
    },
    fileUrl: {
      type: String,
    },
    fileName: {
      type: String,
    },
    coverPath: {
      type: String,
    },
    title: {
      type: String,
    },
    action: {
      type: String,
      default: 'dlFlie',
    },
    tags: {
      type: Array,
    },
    currentPage: {
      type: [Number, String],
    },
  },
  components: {
    DownloadButton,
    DgImg,
  },
  data() {
    return {
      disable: false,
      send: false,
    };
  },
  computed: {
    ...mapState(['isLogin']),
    iconLabel() {
      return this.isLogin ? '下載文件' : '登入下載';
    },
    iconName() {
      return 'download';
      // return this.isLogin ? 'download' : 'false';
    },
  },
  methods: {
    ...mapMutations({
      showNotifyError: 'setNotifyError',
      mutationSetModal: 'setModal',
      showNotifySuccess: 'setNotifySuccess',
    }),
    // sendDownload(res) {

    // },
    // fileDownload(res) {
    //   console.log('res', res.type);
    //   const targetUrl = window.URL.createObjectURL(new Blob([res]));
    //   const link = document.createElement('a');
    //   link.href = targetUrl;
    //   link.setAttribute('download', `下載檔案.${
    //     (() => {
    //       let y = '';
    //       if (res.type.indexOf('spreadsheetml.sheet') > 0) {
    //         y = 'xls';
    //       } else if (res.type.indexOf('msword') > 0) {
    //         y = 'doc';
    //       } else if (res.type.indexOf('wordprocessingml.document') > 0) {
    //         y = 'docx';
    //       } else if (res.type.indexOf('jpeg') > 0) {
    //         y = 'jpeg';
    //       } else if (res.type.indexOf('png') > 0) {
    //         y = 'png';
    //       } else if (res.type.indexOf('gif') > 0) {
    //         y = 'gif';
    //       } else if (res.type.indexOf('pdf') > 0) {
    //         y = 'pdf';
    //       }
    //       return y;
    //     })()
    //   }`);
    //   document.body.appendChild(link);
    //   link.click();
    // },
    async dlFlie(uuid) {
      if (!this.isLogin) {
        // this.showNotifyError('請先登入');
        // this.mutationSetModal({
        //   show: false,
        // });
        this.$emit('close');
        this.$router.push({ name: 'Login', params: { uuid, page: this.currentPage } });
        return;
      }
      if (this.disable) {
        if (this.send) {
          this.showNotifyError('下載連結已發送，請勿重覆點擊下載');
        } else {
          this.showNotifyError('請勿重覆點擊下載');
        }
        return;
      }
      this.disable = true;
      const { status, message } = await requestApi('articles.send', { uuid });
      if (status) {
        // this.send = true;
        this.$emit('close');
        this.$router.push({ name: 'Success' });
        // this.showNotifySuccess('下載連結已寄至您的信箱');
      } else {
        this.showNotifyError(message);
      }
      // console.info('👉👉 res', res);
      // const res = await requestApi('downloads', {
      //   uuid,
      // });

      // if (res.data === null) {
      //   this.showNotifyError('無法取得下載資訊, 請先登入');
      //   this.mutationSetModal({
      //     show: false,
      //   });
      //   this.$router.push({ name: 'Login' });
      // } else {
      //   console.log(uuid, res);
      //   this.fileDownload(res);
      // }
    },
  },
};
</script>
<style lang="scss">
.editor-content {
  a {
    color: #3798E4;
  }
}
</style>
