<template>
  <div class="p-2 h-full gtm-knowledge-card">
    <div
      class="bg-white rounded-xl shadow cursor-pointer overflow-hidden
        flex relative h-full items-stretch"
      :class="isSmall ? 'flex-row p-3.5' : 'flex-col'"
    >

      <!-- img -->
      <div
        class="relative overflow-hidden"
        :class="isSmall ? 'rounded-md mr-3' : ''"
        :style="isSmall ? {'min-width': '180px'} : null"
      >
        <DgImg
          :src="imgUrl || previewImgUrl"
          :ratio="isSmall ? 180/100 : 357/200"
          :class="isSmall ? 'rounded-md' : 'absolute inset-0'"
        />
        <div
          class="flex flex-row absolute right-1 bottom-1
          lg:right-2 lg:bottom-2"
        >

        </div>
      </div>
      <div
        :class="isSmall ? '' : 'px-5 py-4 flex flex-col items-stretch flex-grow'"
      >
        <!-- tag -->
        <div
          class="flex-nowrap grid-flow-col grid"
        >
          <HashtagList
            :list="tag"
          />
        </div>
        <!-- title -->
        <div>
          <p
            class="text-ahs-800 mt-0 lg:mt-2 lg:mb-8 line-clamp-2"
            :class="isSmall ? 'text-base' : 'mb-2.5 text-xl'"
          >{{title}}</p>
        </div>
      </div>
      <div
        class="flex items-center justify-center pb-5"
      >
        <p
          class="lg:text-base text-sm text-primary"
        >瞭解更多</p>
        <DgIcon
          size="10"
          name="caret-right"
          color="primary"
          class="ml-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DgImg from '@/components/base/DgImg.vue';
import DgIcon from '@/components/base/DgIcon.vue';
import HashtagList from '@/components/HashtagList.vue';
// import Avatar from '@/components/app/header/Avatar.vue';

export default {
  name: 'Card',
  inject: ['device'],
  props: {
    cardMobileType: {
      type: String,
      default: 'origin',
      validator(v) {
        return ['origin', 'small'].indexOf(v) !== -1;
      },
    },
    imgUrl: {
      type: String,
    },
    tag: {
      type: Array,
    },
    title: {
      type: String,
    },
    avatar: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  components: {
    HashtagList,
    DgImg,
    DgIcon,
    // Avatar,
  },
  data() {
    return {
      previewImgUrl: 'imgs/imgPreviewPlaylist',
    };
  },
  computed: {
    isMobile() {
      return this.device.isMobile;
    },
    isSmall() {
      return this.isMobile && this.cardMobileType === 'small';
    },
  },
  methods: {
    // 前往內容頁
  },
};
</script>
